import * as React from "react";
import type { GatsbySSR } from "gatsby";
import "./src/styles/global.css";

import "@fontsource/inter";
import "@fontsource/inter/300.css";
import "@fontsource/inter/500.css";
import "@fontsource/inter/700.css";
import "@fontsource/inter/900.css";

import "@fontsource/montserrat";
import "@fontsource/montserrat/300.css";
import "@fontsource/montserrat/500.css";
import "@fontsource/montserrat/600.css";
import "@fontsource/montserrat/700.css";

export const wrapPageElement: GatsbySSR["wrapPageElement"] = ({ element }) => {
  return <>{element}</>;
};
